import React from 'react';
// eslint could not import PageProps, I don't know why so
// eslint-disable-next-line import/named
import { PageProps, Link } from 'gatsby';
import { Layout } from '@components';

const NotFoundPage: React.FC<PageProps> = () => (
    <Layout>
        <article className="pt-24 text-center">
            <h1 className="content-title">Error 404</h1>
            <section className="content-body">
                Stránka nenalezena, <Link to="/">vraťte se domů</Link> a zkus to znovu
            </section>
        </article>
    </Layout>
);

export default NotFoundPage;
